import './marketing/analytics-events'
import './marketing/header'
import './marketing/link-query-param-overrider'

import {lazyDefine} from '@github/catalyst'

lazyDefine({
  'active-global-banners': () => import('./marketing/active-global-banners'),
  'global-banner': () => import('./marketing/global-banner'),
})
