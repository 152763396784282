/*
 * Used to override or add query parameters (utm_campaign, utm_source, utm_medium, ocid)
 * of a HTMLAnchorElement based on query parameters found in `location.search` query string
 *
 * This creates persistance of these parameters across the site, meaning that the parameters from social media
 * links towards the makreting sites will persist and eventually reach the marketing forms
 *
 * NOTE: The only downside of this script is that we don't check for the links that lead outside of doctom, so they
 * will persist there. I couldn't add another check for that since we already have too many checks and this
 * script needs to react on `touchstart` which must be very performant.
 */

class QueryParamOverrider {
  paramsToOverride: string[]
  queryString: string
  collectedParamsMap: Record<string, string>
  collectedParams: string[]
  queryRe: RegExp

  constructor() {
    this.paramsToOverride = ['utm_campaign', 'utm_medium', 'utm_source', 'ocid']
    this.queryString = document.location.search
    this.collectedParamsMap = {}
    this.collectedParams = []
    this.queryRe = new RegExp(`(${this.paramsToOverride.join('|')})=([^=&]*)`, 'gi')

    if (!this.queryString || !this.queryRe.test(this.queryString)) return

    this.collectParams()
    this.bindEvents()
  }

  collectParams() {
    for (const paramName of this.paramsToOverride) {
      const re = new RegExp(`${paramName}=([^=&]*)`, 'i')
      const matches = re.exec(this.queryString)
      re.lastIndex = 0
      if (!matches || !matches[1]) continue
      this.collectedParamsMap[paramName] = matches[1]
      this.collectedParams.push(`${paramName}=${matches[1]}`)
    }
  }

  overrideParams(element: HTMLAnchorElement) {
    let href = element.getAttribute('href')
    let hrefHash = ''
    if (!href || /^javascript:|^mailto:|^tel:|^#/.test(href)) return

    if (href.indexOf('#') !== -1) {
      const hrefHashSplit = href.split('#')
      if (hrefHashSplit.length !== 2) return
      if (hrefHashSplit[0]) href = hrefHashSplit[0]
      if (hrefHashSplit[1]) hrefHash = `#${hrefHashSplit[1]}`
    }

    if (href.indexOf('?') === -1) {
      href += `?${this.collectedParams.join('&')}`
    } else {
      for (const paramName of this.paramsToOverride) {
        if (!this.collectedParamsMap.hasOwnProperty(paramName)) continue
        const re = new RegExp(`${paramName}=([^=&]*)`, 'i')
        const matches = re.exec(href)
        re.lastIndex = 0
        if (!matches) {
          if (this.collectedParamsMap.hasOwnProperty(paramName))
            href += `&${paramName}=${this.collectedParamsMap[paramName]}`
          continue
        }
        href = href.replace(re, `${paramName}=${this.collectedParamsMap[paramName]}`)
      }
    }

    href = href + hrefHash
    element.setAttribute('data-override-applied', 'true')
    element.setAttribute('href', href)
  }

  eventReaction(event: Event) {
    const element = event.target as HTMLAnchorElement
    if (!element || element.tagName !== 'A' || element.hasAttribute('data-override-applied')) return
    this.overrideParams(element)
  }

  bindEvents() {
    document.body.addEventListener('mouseenter', this.eventReaction.bind(this), true)
    document.body.addEventListener('touchstart', this.eventReaction.bind(this), {passive: true, capture: true})
  }
}

new QueryParamOverrider()
